import React from 'react';
import titleBorder from '../../assets/title_border.png'
import './TitleComponent.css';

const TitleComponent = (props) => {
  const { items, subtitle } = props;
  console.log(items);
  return (
    <div className='title_box'>
      <div>
        {items.map((item, index) => {
          return <span key={index} className={item.className}>{item.content}</span>
        })}
      </div>
      <div className='subtitle'>
        {subtitle}
      </div>
      <div>
        <img src={titleBorder} alt='titleBorder' />
      </div>
    </div>
  );
};

export default TitleComponent;