import logo from './assets/logo.png'
import logoMobile from './assets/logo_mobile.png'
import footPc from './assets/foot_pc.png'
import footMobile from './assets/foot_mobile.png'
import banner1 from './assets/banner1.png'
import why_study_1 from './assets/why_study_1.png'
import why_study_2 from './assets/why_study_2.png'
import why_study_3 from './assets/why_study_3.png'
import why_study_4 from './assets/why_study_4.png'
import fit_1 from './assets/fit_1.png'
import fit_2 from './assets/fit_2.png'
import fit_3 from './assets/fit_3.png'
import fit_4 from './assets/fit_4.png'
import job_case_1 from './assets/job_case_1.png'
import job_case_2 from './assets/job_case_2.png'
import job_case_3 from './assets/job_case_3.png'
import job_case_4 from './assets/job_case_4.png'
import job_case_5 from './assets/job_case_5.png'
import job_case_6 from './assets/job_case_6.png'
import job_case_7 from './assets/job_case_7.png'
import job_case_8 from './assets/job_case_8.png'
import study_way from './assets/study_way.png'
import study_way_mobile from './assets/study_way_mobile.jpg'

import student_work_1 from './assets/student_work_1.png'
import student_work_2 from './assets/student_work_2.png'
import student_work_3 from './assets/student_work_3.png'
import student_work_4 from './assets/student_work_4.png'
import student_work_5 from './assets/student_work_5.png'
import student_work_6 from './assets/student_work_6.png'
import student_work_7 from './assets/student_work_7.png'
import student_work_8 from './assets/student_work_8.png'
import student_work_9 from './assets/student_work_9.png'
import student_work_10 from './assets/student_work_10.png'

import teacher from './assets/teacher.png'

import why_choose_1 from './assets/why_choose_1.png'
import why_choose_2 from './assets/why_choose_2.png'
import why_choose_3 from './assets/why_choose_3.png'
import why_choose_4 from './assets/why_choose_4.png'
import why_choose_5 from './assets/why_choose_5.png'

import about from './assets/about.png'
import about_mobile from './assets/about_mobile.jpg'

import year_2019 from './assets/year_2019.png'
import year_2020 from './assets/year_2020.png'
import year_2021 from './assets/year_2021.png'
import year_2022 from './assets/year_2022.png'
import year_2023 from './assets/year_2023.png'

import company_pic_1 from './assets/company_pic_1.png'
import company_pic_2 from './assets/company_pic_2.png'
import company_pic_3 from './assets/company_pic_3.png'

import cooperatives_1 from './assets/cooperatives_1.png'
import cooperatives_2 from './assets/cooperatives_2.png'
import cooperatives_3 from './assets/cooperatives_3.png'
import cooperatives_4 from './assets/cooperatives_4.png'
import cooperatives_5 from './assets/cooperatives_5.png'
import cooperatives_6 from './assets/cooperatives_6.png'
import cooperatives_7 from './assets/cooperatives_7.png'
import cooperatives_8 from './assets/cooperatives_8.png'
import cooperatives_9 from './assets/cooperatives_9.png'
import cooperatives_10 from './assets/cooperatives_10.png'

import close from './assets/close.png'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import TitleComponent from './component/TitleComponent/TitleComponent';
import { useRef, useState, useEffect } from 'react';

function App() {
  const titleBox = useRef(null);

  const [foot, setFoot] = useState(footPc);
  const [show, setShow] = useState(false);

  const setFootPc = () => {
    setFoot(footPc)
  }

  const setFootMobile = () => {
    setFoot(footMobile)
  }

  useEffect(() => {
    // 组件挂载完成时的逻辑
    console.log(`Component mounted with props:`);
    const viewportWidth = window.innerWidth;
    if (viewportWidth >= 1304) {
      setFootPc();
    } else {
      setFootMobile();
    }
    // 清理函数
    return () => {
      console.log(`Component will unmount`);
    };
  }, []);


  return (
    <div className="App">
      <header className="App-header" id='home'>
        <img className='pc' src={logo} alt='logo' />
        <img className='mobile mobile-logo' src={logoMobile} alt='logo' />
        <div className='title-box' ref={titleBox} onClick={(e) => clickTitle(e)}>
          <a href='#home' className='head-title first-head-title head-title-active'>首页</a>
          <a href='#class' className='head-title'>精品课程</a>
          <a href='#studentwork' className='head-title'>学员作品展</a>
          <a href='#whychoose' className='head-title'>我们的优势</a>
          <a href='#about' className='head-title'>公司简介</a>
          {/* <a href='#cooperatives' className='head-title'>合作企业</a> */}
        </div>
      </header>
      <div className='bannerBox'>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={banner1}
              alt="First slide"
            />
          </Carousel.Item>
          {/* <Carousel.Item>
            <img
              className="d-block w-100"
              src={banner2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={footPc}
              alt="Third slide"
            />
          </Carousel.Item> */}
        </Carousel>
      </div>
      <div id='class'></div>
      <TitleComponent items={[{ className: 'black', content: '为什么要学' }, { className: 'green', content: '全媒体运营课程' }]} subtitle='高薪待遇和兼职是常态，上升空间平稳宽广是潮流'></TitleComponent>
      <div className='row-box'>
        <div><img src={why_study_1} alt='why_study_1' /></div>
        <div><img src={why_study_2} alt='why_study_2' /></div>
        <div><img src={why_study_3} alt='why_study_3' /></div>
        <div><img src={why_study_4} alt='why_study_4' /></div>
      </div>
      <button onClick={() => setShow(true)} className='primary-btn'>了解全媒体运营前景👉</button>
      <TitleComponent items={[{ className: 'black', content: '全媒体运营课' }, { className: 'green', content: '适合哪些人？' }]} subtitle='高薪待遇和兼职是常态，上升空间平稳宽广是潮流'></TitleComponent>
      <div className='row-box'>
        <div><img src={fit_1} alt='fit_1' /></div>
        <div><img src={fit_2} alt='fit_2' /></div>
        <div><img src={fit_3} alt='fit_3' /></div>
        <div><img src={fit_4} alt='fit_4' /></div>
      </div>
      <div className='bgF6F6F6'>
        <TitleComponent items={[{ className: 'black', content: '就职方向&' }, { className: 'green', content: '薪资水平' }]} subtitle='全职岗位类型与兼职接单平均薪资'></TitleComponent>
        <div className='single-box'>
          <div className='title'><span>部分案例</span><span className='weight300'> Some cases</span></div>
          <div className='cases job-caces'>
            <div><img src={job_case_1} alt='job_case_1' /></div>
            <div><img src={job_case_2} alt='job_case_2' /></div>
            <div><img src={job_case_3} alt='job_case_3' /></div>
            <div><img src={job_case_4} alt='job_case_4' /></div>
            <div><img src={job_case_5} alt='job_case_5' /></div>
            <div><img src={job_case_6} alt='job_case_6' /></div>
            <div><img src={job_case_7} alt='job_case_7' /></div>
            <div><img src={job_case_8} alt='job_case_8' /></div>
          </div>
        </div>
      </div>
      <TitleComponent items={[{ className: 'black', content: '运营课程' }, { className: 'green', content: '四位一体学习法' }]} subtitle='学-练-测-解结合，知识点掌握更牢固'></TitleComponent>
      <div className='img-box pc'><img src={study_way} alt='study_way' /></div>
      <div className='img-box mobile'><img src={study_way_mobile} alt='study_way_mobile' /></div>
      <button onClick={() => setShow(true)} className='primary-btn mt90'>领取免费运营资料👉</button>
      <div id='studentwork'></div>
      <TitleComponent items={[{ className: 'black', content: '学员作品' }, { className: 'green', content: '展示' }]} subtitle='老师指导作品集，用实战成果说话'></TitleComponent>
      <div className='single-box'>
        <div className='title'><span>部分案例</span><span className='weight300'> Some cases</span></div>
        <div className='cases'>
          <Carousel>
            <Carousel.Item>
              <div className='carousel-row'>
                <div><img src={student_work_1} alt='student_work_1' /></div>
                <div><img src={student_work_2} alt='student_work_2' /></div>
                <div><img src={student_work_3} alt='student_work_3' /></div>
                <div><img src={student_work_4} alt='student_work_4' /></div>
                <div><img src={student_work_5} alt='student_work_5' /></div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='carousel-row'>
                <div><img src={student_work_6} alt='student_work_6' /></div>
                <div><img src={student_work_7} alt='student_work_7' /></div>
                <div><img src={student_work_8} alt='student_work_8' /></div>
                <div><img src={student_work_9} alt='student_work_9' /></div>
                <div><img src={student_work_10} alt='student_work_10' /></div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <button onClick={() => setShow(true)} className='primary-btn mt65'>了解更多学员作品👉</button>
      <TitleComponent items={[{ className: 'black', content: '实战派' }, { className: 'green', content: '终身制服务' }, { className: 'black', content: '运营导师' }]} subtitle='实战名师项目教学，终身制服务精学致用'></TitleComponent>
      <div className='img-box'><img src={teacher} alt='teacher' /></div>
      <button onClick={() => setShow(true)} className='primary-btn mt65'>申请一对一指导👉</button>
      <div id='whychoose'></div>
      <TitleComponent items={[{ className: 'black', content: '为什么要' }, { className: 'green', content: '选择风行教育' }]} subtitle='实战派名师全程陪跑，高质量服务导向就业'></TitleComponent>
      <div className='cases single-box why-choose-box'>
        <div><img src={why_choose_1} alt='why_choose_1' /></div>
        <div><img src={why_choose_2} alt='why_choose_2' /></div>
        <div><img src={why_choose_3} alt='why_choose_3' /></div>
        <div><img src={why_choose_4} alt='why_choose_4' /></div>
        <div><img src={why_choose_5} alt='why_choose_5' /></div>
      </div>
      <div id='about' className='cases single-box nowrap text-left pc-flex'>
        <div><img src={about} alt='about' /></div>
        <div className='about-content'>
          <div>关于我们</div>
          <span>风行是一家专注于高级运营师培养的教育品牌，公司自成立以来秉承着“团结、创新、务实、专业”的团队精神，坚持以“为客户提供优质的服务”为原则，以“建一流企业，做一流服务，创一流品牌”为宗旨，努力打造成为全国知名的教育辅导专家！</span>
        </div>
      </div>
      <div className='cases single-box nowrap text-left year-box  pc-flex'>
        <div>
          <img src={year_2019} alt='year_2019' />
          <div className='year'>2019年</div>
          <div className='year-content'>起步搭建风行品牌工作室，专注线下运营课程培训。</div>
        </div>
        <div>
          <img src={year_2020} alt='year_2020' />
          <div className='year'>2020年</div>
          <div className='year-content'>业界展露头角，新增线下日语、雅思小语种培训。</div>
        </div>
        <div>
          <img src={year_2021} alt='year_2021' />
          <div className='year'>2021年</div>
          <div className='year-content'>疫情带来发展契机，启动线上教学 大厂合作完善实战教学方法思路。</div>
        </div>
        <div>
          <img src={year_2022} alt='year_2022' />
          <div className='year'>2022年</div>
          <div className='year-content'>全面转型线上教育，线上学员激增，定下品牌名称“风行教育”。</div>
        </div>
        <div>
          <img src={year_2023} alt='year_2023' />
          <div className='year'>2023年</div>
          <div className='year-content'>品牌全面升级，师资力量及课程服务，引领行业向专业化、规范化航线。</div>
        </div>
      </div>
      <div className='img-box mobile'><img src={about_mobile} alt='about_mobile' /></div>
      <div className='bgF6F6F6'>
        <TitleComponent items={[{ className: 'black', content: '公司实景' }]} subtitle='你点点滴滴的进步，也是我们点点滴滴珍贵的回忆'></TitleComponent>
        <div className='single-box'>
          <div className='cases company-pic-box'>
            <div><img src={company_pic_1} alt='company_pic_1' /></div>
            <div><img src={company_pic_2} alt='company_pic_2' /></div>
            <div><img src={company_pic_3} alt='company_pic_3' /></div>
          </div>
        </div>
        <div id='cooperatives'></div>
        {/* <TitleComponent items={[{ className: 'black', content: '合作企业' }]} subtitle='强实力，强认可'></TitleComponent>
        <div className='single-box'>
          <div className='cases cooperatives-box'>
            <div><img src={cooperatives_1} alt='cooperatives_1' /></div>
            <div><img src={cooperatives_2} alt='cooperatives_2' /></div>
            <div><img src={cooperatives_3} alt='cooperatives_3' /></div>
            <div><img src={cooperatives_4} alt='cooperatives_4' /></div>
            <div><img src={cooperatives_5} alt='cooperatives_5' /></div>
            <div><img src={cooperatives_6} alt='cooperatives_6' /></div>
            <div><img src={cooperatives_7} alt='cooperatives_7' /></div>
            <div><img src={cooperatives_8} alt='cooperatives_8' /></div>
            <div><img src={cooperatives_9} alt='cooperatives_9' /></div>
            <div><img src={cooperatives_10} alt='cooperatives_10' /></div>
          </div>
        </div> */}
      </div>
      <footer className='App-footer'>
        <img className='foot-img' src={foot} alt='logo' />
      </footer>
      {show && <div className="container-fluid">
        <div id="wx">
          <div onClick={() => setShow(false)}><img src={close} className='close' alt='close' /></div>
        </div>
      </div>}
    </div>
  );



  function clickTitle($e) {
    if ($e.target.className.indexOf('head-title') === -1) {
      return;
    }
    let children = Array.from(titleBox.current.children);
    children.forEach(item => {
      const className = item.className.split(' ');
      const i = className.findIndex(i => i === 'head-title-active');
      if (i !== -1) {
        className.splice(i, 1);
        item.className = className.join(' ');
      }
    })
    $e.target.className += ' head-title-active';
  }
}

export default App;
